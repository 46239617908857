@import '../../vars';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
}

form {
  display: flex;
  flex: 1;
}

.separator {
  height: 80%;
  width: 1px;
  background-color: $text-color;
  opacity: 0.5;
}
