@import '../../vars.scss';

.container {
  margin-bottom: 20px;
}
.title {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 8px;
  color: $text-color;
}
.select {
  width: 200px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
  .select {
    width: 90%;
  }
}
