@import '../../vars.scss';

.container {
  padding: 15px;
  border-radius: 10px;
  //border: grey 1px solid;
  box-shadow: 5px 5px 9px 1px rgba(0,0,0,0.2);
  height: 100%;
  width: 700px;
  overflow-y: scroll;
  margin-right: 20px;
}
.text {
  line-height: 26px;
  margin-bottom: 10px;
  font-size: 1.0em;
  font-weight: 600;
}
.color0 {
  color: $primary-color;
}
.color1 {
  color: $text-color;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 90%;
    margin-bottom: 20px;
  }
}
